import React, {
  MouseEvent,
  useState,
  useRef,
  useEffect,
  useContext,
  FunctionComponent,
} from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GlobalContext, ContextType, yearType } from './GlobalContextProvider'
import * as subSelectorStyles from './SubSelector.module.css'

interface subSelectorProps {
  navRef: React.RefObject<HTMLButtonElement | HTMLAnchorElement>
  rssRef: React.RefObject<HTMLButtonElement>
  showSubSelector: boolean
  closeSubSelector: () => void
}

const SubSelector: FunctionComponent<subSelectorProps> = (props) => {
  const [selectedYear, setSelectedYear] = useState('')
  const allYearRef = useRef<HTMLAnchorElement>(null)
  const allCountriesRef = useRef<HTMLAnchorElement>(null)
  const closeDialogRef = useRef<HTMLButtonElement>(null)
  const subSelClass = `${subSelectorStyles.subSelector}${
    props.showSubSelector ? ` ${subSelectorStyles.showSubSelector}` : ''
  }`
  const { years } = useContext(GlobalContext) as ContextType
  let allYearURL: string
  let selYear: yearType | undefined
  if (selectedYear) {
    selYear = years.find((el) => el.id === parseInt(selectedYear, 10))
    allYearURL = `/map/${selYear?.title}`
  } else {
    allYearURL = '/map'
  }

  useEffect(() => {
    if (!props.showSubSelector) {
      return
    }
    if (selectedYear) {
      allCountriesRef.current?.focus()
    } else {
      allYearRef.current?.focus()
    }
  }, [props.showSubSelector, selectedYear])

  return (
    <div
      className={subSelClass}
      onKeyDown={(e) => {
        if ((e.target as HTMLElement).dataset.button === 'first') {
          if (e.key === 'Tab' && e.shiftKey) {
            closeDialogRef.current?.focus()
            e.preventDefault()
          }
        }
        if ((e.target as HTMLElement).dataset.button === 'last') {
          if (e.key === 'Tab' && !e.shiftKey) {
            allYearRef.current?.focus()
            e.preventDefault()
          }
        }
        if (e.key === 'Escape') {
          setSelectedYear('')
          props.closeSubSelector()
        }
      }}
    >
      <div className={`${subSelectorStyles.subSelHead}`}>
        Pick year and country
      </div>
      <div>
        <ul>
          <li key="all">
            <Link
              to="/map"
              ref={allYearRef}
              data-button="first"
              onFocus={() => {
                if (!props.showSubSelector) {
                  props.navRef.current?.focus()
                }
              }}
            >
              All years
            </Link>
          </li>
          {years.map((entry) => (
            <li
              key={entry.id}
              className={`${
                entry.id === parseInt(selectedYear, 10) ? 'selected' : ''
              }`}
            >
              <button
                value={entry.id}
                onClick={(e: MouseEvent<HTMLButtonElement>) =>
                  setSelectedYear((e.target as HTMLButtonElement).value)
                }
              >
                {entry.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div>
        {selectedYear && (
          <ul>
            <li key="all">
              <Link to={allYearURL} ref={allCountriesRef}>
                All Countries
              </Link>
            </li>
            {selYear?.countries.map((el) => (
              <li key={el.slug}>
                <Link to={`${allYearURL}/${el.title}`}>{el.title}</Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      <button
        className={`${subSelectorStyles.subSelClose}`}
        aria-label="Close dialog"
        ref={closeDialogRef}
        data-button="last"
        onFocus={() => {
          if (!props.showSubSelector) {
            props.rssRef.current?.focus()
          }
        }}
        onClick={() => {
          setSelectedYear('')
          props.closeSubSelector()
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            className={`${subSelectorStyles.closeDialogButton}`}
          />
          <desc>Close image</desc>
        </svg>
      </button>
    </div>
  )
}

export default SubSelector
