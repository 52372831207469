import React, {
  useState,
  useRef,
  FunctionComponent,
  RefObject,
  useEffect,
} from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Nav, { updateNav } from './Nav'
import SEO from './SEO'
import * as homeStyles from './Home.module.css'
import SubSelector from './SubSelector'

interface DataType {
  allWpPage: {
    edges: {
      node: {
        content: string
      }
    }[]
  }
}

const Home: FunctionComponent = () => {
  const [showSubSelector, setShowSubSelector] = useState(false)
  const [showPasteMessage, setShowPasteMessage] = useState(false)
  updateNav()
  const data: DataType = useStaticQuery(graphql`
    query {
      allWpPage(filter: { slug: { eq: "root" } }) {
        edges {
          node {
            content
          }
        }
      }
    }
  `)
  const navRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
  const mapRef = useRef<HTMLButtonElement>(null)
  const rssRef = useRef<HTMLButtonElement>(null)
  return (
    <div className={homeStyles.homePage}>
      <div className={homeStyles.backgroundImage}>
        <picture>
          <source
            srcSet="/bertie-beach_wkbu92_c_scale,w_190.webp 190w,
            /bertie-beach_wkbu92_c_scale,w_573.webp 573w,
            /bertie-beach_wkbu92_c_scale,w_824.webp 824w,
            /bertie-beach_wkbu92_c_scale,w_1057.webp 1057w,
            /bertie-beach_wkbu92_c_scale,w_1462.webp 1462w,
            /bertie-beach_wkbu92_c_scale,w_1920.webp 1920w"
            type="image/webp"
          />
          <source
            srcSet="/bertie-beach_wkbu92_c_scale,w_190.jpg 190w,
            /bertie-beach_wkbu92_c_scale,w_573.jpg 573w,
            /bertie-beach_wkbu92_c_scale,w_824.jpg 824w,
            /bertie-beach_wkbu92_c_scale,w_1057.jpg 1057w,
            /bertie-beach_wkbu92_c_scale,w_1462.jpg 1462w,
            /bertie-beach_wkbu92_c_scale,w_1920.jpg 1920w"
            type="image/jpeg"
          />
          <img src="/bertie-beach.jpg" alt="Bertie on the beach" />
        </picture>
      </div>
      {showSubSelector ? <div className={homeStyles.modalMask} /> : null}
      <div className={homeStyles.homeTitle}>Our European Tour</div>
      <main
        className={homeStyles.homeDescription}
        dangerouslySetInnerHTML={{
          __html: data.allWpPage.edges[0].node.content,
        }}
      />
      <button
        className={`${homeStyles.homeButton} ${homeStyles.mapButton}`}
        onClick={() => setShowSubSelector(!showSubSelector)}
        ref={mapRef}
      >
        Map
      </button>
      <button
        className={`${homeStyles.homeButton} ${homeStyles.galleryButton} ${homeStyles.hide}`}
      >
        Gallery
      </button>
      <Link
        className={`${homeStyles.homeButton} ${homeStyles.blogButton}`}
        to="/blogPage"
      >
        <div>Blog</div>
      </Link>
      <button
        className={homeStyles.rssFeed}
        ref={rssRef}
        onClick={() => {
          document.addEventListener('copy', function copyRSS(e) {
            e.clipboardData?.setData(
              'text/plain',
              'https://europe.thegreens.co.uk/feed.xml'
            )
            e.preventDefault()
            document.removeEventListener('copy', copyRSS)
          })
          document.execCommand('copy')
          setShowPasteMessage(true)
          window.setTimeout(() => setShowPasteMessage(false), 5000)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <desc>RSS icon</desc>
          <circle
            className={homeStyles.RSSicon}
            cx="6.18"
            cy="17.82"
            r="2.18"
          />
          <path
            className={homeStyles.RSSicon}
            d="M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z"
          />
        </svg>
        RSS Feed
        {showPasteMessage && (
          <div className={homeStyles.pasteMessage}>
            Link https://europe.thegreens.co.uk/feed.xml copied to clipboard
          </div>
        )}
      </button>
      <SEO
        title="Our European Tour"
        description={data.allWpPage.edges[0].node.content}
        image="/bertie-beach-small.jpg"
        type="article"
      />
      <SubSelector
        navRef={navRef}
        rssRef={rssRef}
        showSubSelector={showSubSelector}
        closeSubSelector={() => {
          setShowSubSelector(!showSubSelector)
          mapRef.current?.focus()
        }}
      />
      <Nav ref={navRef} />
    </div>
  )
}

export default Home
