// extracted by mini-css-extract-plugin
export var homePage = "Home-module--homePage--xkNtI";
export var backgroundImage = "Home-module--backgroundImage--34Hqd";
export var homeButton = "Home-module--homeButton--mxNjI";
export var rssFeed = "Home-module--rssFeed--3xlEo";
export var mapButton = "Home-module--mapButton--38oQr";
export var galleryButton = "Home-module--galleryButton--16bh8";
export var blogButton = "Home-module--blogButton--BP8x8";
export var hide = "Home-module--hide--2ufIF";
export var homeTitle = "Home-module--homeTitle--Plmk8";
export var homeDescription = "Home-module--homeDescription--32HlH";
export var RSSicon = "Home-module--RSSicon--Nivwe";
export var modalMask = "Home-module--modalMask--3sId1";
export var pasteMessage = "Home-module--pasteMessage--3yJI_";
export var navContainer = "Home-module--navContainer--2MMmd";